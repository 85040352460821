import React from "react"
import styled from "styled-components"
import Img from "gatsby-image"
import widths from "../utils/dimensions"

let Container = styled.div`
  width: 100%;
  height: 0;
  padding-top: 56.25%;
  position: relative;
  .gatsby-image-wrapper{
    position: absolute !important;
    object-fit: cover;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
  }
  @media screen and (min-width: ${ widths.mobileMenuBreakPoint }){
    width: 100%;
    height: 60vh;
    padding-top: 0;
    .gatsby-image-wrapper{
      object-fit: cover;
      height: 100%;
      width: 100%;
    }
  }
`

const HeaderImage = ({ image }) => {
  return(
    <Container>
      <Img
        fluid={ image.imageFile.childImageSharp.fluid }
        alt={ image.altText }
      />
    </Container>
  )}

export default HeaderImage
