import React from "react"
import { graphql, Link, useStaticQuery } from "gatsby"
import HeaderImage from "../components/header-image"
import Page from "../components/page"
import Section from "../components/section"
import MoreInfoTiles from "../components/more-info-tiles"
import ShowcaseMobile from "../components/showcase-mobile"
import ShowcaseDesktop from "../components/showcase-desktop"
import colours from "../utils/colours"
import Img from "gatsby-image"
import styled from "styled-components"

let IntegrationIcons = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 60px;
  .gatsby-image-wrapper{
    width: 300px;
    margin: 0 20px;
    img{
      object-fit: contain !important;
    }
  }
`

const FeaturesPage = ({ location }) => {
  const data = useStaticQuery(graphql`
      {
          wpgraphql {
              pageBy(uri: "feature-showcase") {
                  id
                  featureShowcasePage {
                      headerImage {
                          sourceUrl
                          altText
                          imageFile {
                              childImageSharp {
                                  fluid (quality: 90) {
                                      ...GatsbyImageSharpFluid_withWebp
                                  }
                              }
                          }
                      }
                      amazonAlexaIcon {
                          sourceUrl
                          altText
                          imageFile {
                              childImageSharp {
                                  fluid (quality: 90) {
                                      ...GatsbyImageSharpFluid_withWebp
                                  }
                              }
                          }
                      }
                      googleAssistantIcon {
                          sourceUrl
                          altText
                          imageFile {
                              childImageSharp {
                                  fluid (quality: 90) {
                                      ...GatsbyImageSharpFluid_withWebp
                                  }
                              }
                          }
                      }
                      title
                      text
                  }
                  slug
                  seo {
                      title
                      metaDesc
                  }
              }
          }
      }
  `)
  let seoData = data.wpgraphql.pageBy.seo
  let pageData = data.wpgraphql.pageBy.featureShowcasePage
  let amazonImage = data.wpgraphql.pageBy.featureShowcasePage.amazonAlexaIcon
  let googleImage = data.wpgraphql.pageBy.featureShowcasePage.googleAssistantIcon

  return(
    <Page
      seoTitle={ seoData.title }
      seoDescription={ seoData.metaDesc }
    >
      <HeaderImage
        image={ pageData.headerImage }
      />
      <Section>
        <h1 dangerouslySetInnerHTML={{ __html: pageData.title }}/>
        <p dangerouslySetInnerHTML={{ __html: pageData.text }}/>

        <IntegrationIcons>
          <Img
            fluid={ amazonImage.imageFile.childImageSharp.fluid }
            alt={ amazonImage.altText }
          />
          <Img
            fluid={ googleImage.imageFile.childImageSharp.fluid }
            alt={ googleImage.altText }
          />
        </IntegrationIcons>

      </Section>

      <ShowcaseMobile/>

      <ShowcaseDesktop/>

      <Section backGColour={ colours.highlightGrey }>
        <MoreInfoTiles
          location={ location }
        />
      </Section>

    </Page>
  )
}
export default FeaturesPage
