import React, { useEffect } from "react"
import { graphql, useStaticQuery } from "gatsby"
import Section from "../components/section"
import styled from "styled-components"
import { Tab, TabList, TabPanel, Tabs } from "react-tabs"
import Img from "gatsby-image"
import lozad from "lozad"

let H2 = styled.h2`
  text-align: center;
`
let H4 = styled.h4`
  font-size: 10px;
`
let Container = styled.div`
    &:nth-of-type(even){
      .tabs-panel{
        flex-direction: row-reverse;
        .text{
          justify-content: flex-end;
        }
      }
      .tab-list{
        right: 40%;
        left: auto;
        padding: 15px 15px 0 0;
        justify-content: flex-end;
        .modes{
          text-align: right;
        }
        @media screen and (min-width: 1200px){
          right: 33.333%;
          left: auto;
          padding: 15px 15px 0 0;
        }
      }
    }
`
let StyledTabs = styled(Tabs)`
  width: 100%;
  margin: 0 auto 80px auto;
  display: flex;
  flex-wrap: wrap;
`
let StyledTabList = styled(TabList)`
  list-style: none;
  display: flex;
  width: 66.666%;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin: 0;
  padding: 15px 0 0 15px;
  position: absolute;
  left: 40%;
  @media screen and (min-width: 1200px){
    left: 33.333%;
  }
`
let StyledTab = styled(Tab)`
  display: flex;
  flex-direction: column;
  padding: 0;
  color: white;
  margin: 0 10px 0 0;
  cursor: pointer;
  width: 80px;
  user-select: none;
  outline-style: none;
  &:last-of-type{
    padding-right: 0;
    margin: 0;
  }
  &.react-tabs__tab--selected{
    span{
      background-color: white;
    }
  }
  span{
    width: 60px;
    height: 60px;
    border-radius: 8px;
    background-color: black;
    position: relative;
    margin: 0 10px;
    .gatsby-image-wrapper{
      position: absolute !important;
      width: 30px;
      height: 30px;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      img{
        position: relative;
        object-fit: contain !important;
      }
    }
  }
  h4{
    text-transform: uppercase;
    margin-top: 10px;
    line-height: 12px;
    font-size: 9px;
    text-align: center;
    text-shadow: 1px 1px 10px rgba(0,0,0,0.2);
  }
`
let StyledTabPanel = styled(TabPanel)`
  visibility: hidden;
  width: 0;
  height: 0;
  position: fixed;
  right: -9999px;
  &.react-tabs__tab-panel--selected{
    width: 100%;
    display: flex;
    visibility: visible;
    height: auto;
    right: 0;
    position: relative;
  }
`
let Text = styled.div`
  width: 40%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  @media screen and (min-width: 1200px){
    width: 33.333%;
  }
`
let TextInner = styled.div`
  width: calc(100% - 40px);
  h3{
    width: 100%;
    text-transform: uppercase; 
    color: white;    
  }
  .gatsby-image-wrapper{
    width: 17px;
    margin-bottom: -20px;
  }
  i{
    width: calc(100% - 20px);
    display: inline-block;
  }
`
let Media = styled.div`
  height: 0;
  width: 60%;
  padding-top: 43.333%;
  position: relative;
  outline-style: none;
  video{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    user-select: none;
    outline-style: none;
    border: none;
  }
  .gatsby-image-wrapper{
    position: absolute !important;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    user-select: none;
    outline-style: none;
  }
  @media screen and (min-width: 1200px){
    width: 66.666%;
  }
`

const ShowcaseDesktop = () => {
  const data = useStaticQuery(graphql`
      {
          wpgraphql {
              pageBy(uri: "feature-showcase") {
                  id
                  featureShowcasePage {
                      modes {
                          title
                          mode {
                              modeName
                              title
                              description
                              tip
                              icon {
                                  sourceUrl
                                  altText
                                  imageFile {
                                      childImageSharp {
                                          fluid {
                                              ...GatsbyImageSharpFluid_withWebp
                                          }
                                      }
                                  }
                              }
                              image {
                                  altText
                                  sourceUrl
                                  imageFile {
                                      childImageSharp {
                                          fluid {
                                              ...GatsbyImageSharpFluid_withWebp
                                          }
                                      }
                                  }
                              }
                              videoDesktop {
                                  mediaItemUrl
                                  mediaFile {
                                      publicURL
                                  }
                              }
                              videoPoster{
                                  altText
                                  sourceUrl
                                  imageFile {
                                      childImageSharp {
                                          fluid {
                                              ...GatsbyImageSharpFluid_withWebp
                                          }
                                          
                                      }
                                  }
                              }
                          }
                      }
                      tipIcon {
                          altText
                          sourceUrl
                          imageFile {
                              childImageSharp {
                                  fluid {
                                      ...GatsbyImageSharpFluid_withWebp
                                  }
                              }
                          }
                      }
                  }
              }
          }
      }
  `)
  let elData = data.wpgraphql.pageBy.featureShowcasePage

  /* Set Lozad to play video element when 100% in view */
  const observer = lozad('.lozad', {
    threshold: 1.0,
    load: function(el) {
      el.play();
    }
  });

  useEffect(() => {
    observer.observe();
  }, []);

  return(
    <Section
      width={ '1500px' }
      backGColour={ 'black' }
      colour={ 'white' }
      desktop
    >

      { elData.modes.map(({ title, mode }, index ) => {
        return (

          <Container key={ index }>
            <H2 dangerouslySetInnerHTML={{ __html: title }}/>
            <StyledTabs
              className={ 'tabs' }
              forceRenderTabPanel={ true }
            >

              { mode.map(({ title, description, tip, image, videoDesktop, videoPoster }, index ) => {
                return (

                  <StyledTabPanel
                    className={ 'tabs-panel' }
                    key={ index }
                  >
                    <Text className={ 'text' }>
                      <TextInner>

                        { title &&
                          <h3 dangerouslySetInnerHTML={{ __html: title }}/>
                        }
                        { description &&
                          <p dangerouslySetInnerHTML={{ __html: description }}/>
                        }
                        { tip &&
                          <>
                            <br/>
                            <Img
                              fluid={ elData.tipIcon.imageFile.childImageSharp.fluid }
                              alt={ elData.tipIcon.altText }
                              align="left"
                            />
                            <i dangerouslySetInnerHTML={{ __html: tip }}/>
                          </>
                        }

                      </TextInner>
                    </Text>
                    <Media>

                      { image &&

                        <Img
                          fluid={ image.imageFile.childImageSharp.fluid }
                          alt={ image.altText }
                        />

                      }

                      { videoDesktop &&

                        <video
                          controls={ true }
                          className={ 'lozad' }
                          preload="none"
                          poster={ videoPoster && videoPoster.imageFile.childImageSharp.fluid.src  }
                        >
                          <source
                            src={ videoDesktop.mediaFile.publicURL }
                            type="video/mp4"
                          />
                        </video>

                      }

                    </Media>
                  </StyledTabPanel>

                )})}

              <StyledTabList className={ 'tab-list' }>

                { mode.map(({ modeName, icon }, index ) => {
                  if ( icon ){
                    return (
                      <StyledTab key={ index }>
                      <span>
                        <Img
                          fluid={ icon.imageFile.childImageSharp.fluid }
                          alt={ icon.altText }
                        />
                      </span>
                        <H4 dangerouslySetInnerHTML={{ __html: modeName }}/>
                      </StyledTab>
                    )
                  }else{
                    return(
                      <StyledTab
                        key={ index }
                        className={ 'hidden' }
                      />
                    )
                  }})}

              </StyledTabList>
            </StyledTabs>
          </Container>

        )})}

    </Section>
  )
}
export default ShowcaseDesktop
