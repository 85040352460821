import React, { useEffect } from "react"
import { graphql, useStaticQuery } from "gatsby"
import Section from "../components/section"
import styled from "styled-components"
import Img from "gatsby-image"
import lozad from "lozad"
import ToTop from '../images/to-top.svg'
import AnchorLink from 'react-anchor-link-smooth-scroll'

let SectionButtonsContainer = styled.div`
  padding-bottom: 30px;
`
let SectionButtons = styled.button`
  width: 100%;
  height: 50px;
  background-color: black;
  border: none;
  color: white;
  margin-bottom: 1px;
  cursor: pointer;
`
let H2 = styled.h2`
  width: 100%;
  text-align: center;
  position: relative;
  span{
    position: absolute;
    top: -30px;
  }
`
let Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`
let Tile = styled.div`
  margin-bottom: 40px;
  @media screen and (min-width: 850px){
    width: calc(50% - 10px);
  }
`
let Text = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  padding: 30px;
  background-color: black;
  color: white;
`
let TextInner = styled.div`
  h3{
    width: 100%;
    text-transform: uppercase; 
    margin-bottom: 10px;
  }
  .gatsby-image-wrapper{
    width: 17px;
    margin-bottom: -20px;
  }
  i{
    display: inline-block;
  }
`
let Media = styled.div`
  height: 0;
  padding-top: 56.25%;
  position: relative;
  outline-style: none;
  video{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    user-select: none;
    outline-style: none;
    border: none;
  }
  .gatsby-image-wrapper{
    position: absolute !important;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    user-select: none;
    outline-style: none;
  }
`
let ToTopButton = styled.div`
  width: 60px;
  height: 60px;
  border-radius: 50%;
  position: fixed;
  bottom: 40px;
  right: 60px;
  cursor:pointer;
  z-index: 1;
  svg{
    fill: rgba(255,255,255,0.5);
  }
`

const ShowcaseMobile = () => {
  const data = useStaticQuery(graphql`
      {
          wpgraphql {
              pageBy(uri: "feature-showcase") {
                  id
                  featureShowcasePage {
                      modes {
                          title
                          mode {
                              modeName
                              title
                              description
                              tip
                              icon {
                                  sourceUrl
                                  altText
                                  imageFile {
                                      childImageSharp {
                                          fluid {
                                              ...GatsbyImageSharpFluid_withWebp
                                          }
                                      }
                                  }
                              }
                              image {
                                  altText
                                  sourceUrl
                                  imageFile {
                                      childImageSharp {
                                          fluid {
                                              ...GatsbyImageSharpFluid_withWebp
                                          }
                                      }
                                  }
                              }
                              videoMobile {
                                  mediaItemUrl
                                  mediaFile {
                                      publicURL
                                  }
                              }
                              videoPoster{
                                  altText
                                  sourceUrl
                                  imageFile {
                                      childImageSharp {
                                          fluid {
                                              ...GatsbyImageSharpFluid_withWebp
                                          }
                                      }
                                  }
                              }
                          }
                      }
                      tipIcon {
                          altText
                          sourceUrl
                          imageFile {
                              childImageSharp {
                                  fluid {
                                      ...GatsbyImageSharpFluid_withWebp
                                  }
                              }
                          }
                      }
                  }
              }
          }
      }
  `)
  let elData = data.wpgraphql.pageBy.featureShowcasePage

  /* Set Lozad to play video element when 100% in view */
  const observer = lozad('.lozad', {
    threshold: 1.0,
    load: function(el) {
      el.play();
    }
  });

  useEffect(() => {
    observer.observe();
  }, []);

  return(
    <Section
      width={ '1500px' }
      backGColour={ 'grey' }
      colour={ 'white' }
      mobile
    >
      <SectionButtonsContainer>

        { elData.modes.map(({ title, mode }, index ) => {
          let anchor = title.replace(/\s+/g, '-').toLowerCase();
          return(

            <AnchorLink href={'#' + anchor} key={ index }>
              <SectionButtons>{ title }</SectionButtons>
            </AnchorLink>

          )})}

      </SectionButtonsContainer>

      { elData.modes.map(({ title, mode }, index ) => {
        let anchor = title.replace(/\s+/g, '-').toLowerCase();
        return (

          <Container key={ index }>
            <H2><span id={ anchor }/>{ title }</H2>

              { mode.map(({ title, modeName, description, tip, image, videoMobile, videoPoster }, index ) => {
                return (

                  <Tile key={ index }>
                      <Media>

                          { image &&
                            <Img
                              fluid={ image.imageFile.childImageSharp.fluid }
                              alt={ image.altText }
                            />
                          }

                          { videoMobile &&
                            <video
                              controls={ true }
                              preload="none"
                              poster={ videoPoster && videoPoster.imageFile.childImageSharp.fluid.src  }
                            >
                                <source
                                  src={ videoMobile.mediaFile.publicURL }
                                  type="video/mp4"
                                />
                            </video>
                          }

                      </Media>
                      <Text>
                          <TextInner>

                              { modeName &&
                                <h3 dangerouslySetInnerHTML={{ __html: modeName }}/>
                              }
                              { description &&
                                <p dangerouslySetInnerHTML={{ __html: description }}/>
                              }
                              { tip &&
                                  <>
                                      <br/>
                                      <Img
                                        fluid={ elData.tipIcon.imageFile.childImageSharp.fluid }
                                        alt={ elData.tipIcon.altText }
                                        align="left"
                                      />
                                      <i dangerouslySetInnerHTML={{ __html: tip }}/>
                                  </>
                              }

                          </TextInner>
                      </Text>
                  </Tile>

                )})}

          </Container>

        )})}

        <AnchorLink href='#site-header'>
          <ToTopButton>
            <ToTop/>
          </ToTopButton>
        </AnchorLink>
    </Section>
  )
}
export default ShowcaseMobile
